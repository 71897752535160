/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { Button, COLOR_PALETTE, Input, Label, Row } from './../AppStyle';
import { MainSection } from './../components/MainSection';
import axios from './../utils/axios';

const AddUser = () => {
    const [redirect, setRedirect] = useState(false);
    const [isFetchingData, setIsFetchingData] = useState(false);

    const { register, handleSubmit, errors, setError } = useForm();

    const onSubmit = data => {
        console.log('Received values of form: ', data);

        if (data.confirm !== data.password) {
            setError('confirm', 'doesntMatch', "Password doesn't match.");
        } else {
            setIsFetchingData(true);
            axios.post(`/api/user/add`, data).then(user => setRedirect(true));
        }
    };

    if (redirect) {
        return <Redirect to="/users" />;
    }

    return (
        <MainSection showLoader={isFetchingData}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Label>Login:</Label>
                    <Input type="email" ref={register({ required: true })} name="login" />
                </Row>

                <Row>
                    <Label>Password:</Label>
                    <Input type="password" ref={register({ required: true })} name="password" />
                </Row>

                <Row>
                    <Label>Confirm password:</Label>
                    <Input type="password" ref={register({ required: true })} name="confirm" />
                </Row>

                <Row>
                    <select ref={register} placeholder="Wybierz rolę dla użytkownika systemu" name="role">
                        <option value="ADMIN">Admin</option>
                        <option value="USER">User</option>
                    </select>
                </Row>

                {errors.confirm && (
                    <Row
                        css={css`
                            color: ${COLOR_PALETTE.pastel_red};
                        `}
                    >
                        {errors.confirm.message}
                    </Row>
                )}

                <Button>Add</Button>
            </form>
        </MainSection>
    );
};

export { AddUser };

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { COLOR_PALETTE, mqMax } from './../AppStyle';
import { MainSection } from './../components/MainSection';
import axios from './../utils/axios';

const Box = styled.div`
    background-color: rgba(255, 255, 255, 0.05);
    padding: 10px;
    margin-bottom: 5px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${mqMax[0]} {
        width: 100%;
    }
    width: 80%;
    max-width: 640px;
`;

const BoxLabel = styled.div`
    opacity: 0.5;
    font-size: 16px;
    text-shadow: 1px 1px 0 #0a0a0a;
`;

const BoxValue = styled.div`
    font-weight: bold;
`;

const PmUnit = () => (
    <Fragment>
        μg/m<sup>3</sup>
    </Fragment>
);

const PmNorm = ({ value, norm }) => {
    const percent = Math.floor((value * 100) / norm);

    const color = percent <= 100 ? COLOR_PALETTE.pastel_green : percent <= 150 ? COLOR_PALETTE.pastel_orange : COLOR_PALETTE.pastel_red;

    return (
        <div
            css={css`
                color: ${color};
                font-weight: normal;
                margin-top: 10px;
            `}
        >
            {percent}%
        </div>
    );
};

const addHours = (timestamp, hours) => timestamp + hours * 60 * 60 * 1000;
const isOffline = timestamp => timestamp < Date.now() - 5 * 60000; // accept 5 minutes delay

const Dashboard = () => {
    const [devices, setDevices] = useState(null);
    const [fullscreen, setFullscreen] = useState(null);

    const fetchDevices = () => {
        axios.get('/api/iot/devices').then(response => setDevices(response.data));
    };

    useEffect(() => {
        fetchDevices();
        const interval = setInterval(fetchDevices, 16000);
        return () => clearInterval(interval);
    }, []);

    const fullscreenStyles = useCallback(
        elementId => {
            return fullscreen === elementId
                ? css`
                      width: 100%;
                      height: 100%;
                      max-width: 100%;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      left: 0;
                      position: fixed;
                      background-color: rgba(0, 0, 0, 0.9);
                      z-index: 10000;
                  `
                : css``;
        },
        [fullscreen]
    );

    const toggleFullscreen = useCallback(elementId => {
        setFullscreen(elementId);
    }, []);

    return (
        <MainSection>
            <h3
                css={css`
                    margin: 0px 0 20px;
                `}
            >
                Live preview:
            </h3>
            {devices &&
                devices.map(
                    device =>
                        device.isCam && (
                            <section
                                key={device.id}
                                css={css`
                                    margin-bottom: 20px;
                                    overflow: hidden;
                                `}
                            >
                                <div
                                    css={css`
                                        ${mqMax[0]} {
                                            width: 100%;
                                        }

                                        width: 80%;
                                        max-width: 640px;
                                        position: relative;
                                        margin-bottom: 20px;

                                        ${fullscreenStyles(device.id)}
                                    `}
                                >
                                    <div
                                        css={css`
                                            position: absolute;
                                            top: 0px;
                                            right: 0px;
                                            padding: 10px;
                                            z-index: 2;
                                            color: ${COLOR_PALETTE.background_gray_light};
                                            text-shadow: 1px 1px black;
                                            font-size: 14px;
                                            text-align: right;
                                            background-color: ${isOffline(device.date)
                                                ? COLOR_PALETTE.pastel_red
                                                : COLOR_PALETTE.pastel_green};
                                        `}
                                    >
                                        {isOffline(device.date) ? 'offline' : 'online'}
                                    </div>
                                    <img
                                        key={device.id}
                                        css={css`
                                            width: 100%;
                                            position: ${fullscreen ? 'absolute' : 'relative'};
                                            top: 0;
                                            bottom: 0;
                                            left: 0;
                                            right: 0;
                                            margin: auto auto;
                                        `}
                                        src={`http://fbg.pl/api/iot/cam/${device.id}?time=${device.date}`}
                                        alt="cam"
                                        onClick={() => toggleFullscreen(fullscreen === null ? device.id : null)}
                                    />
                                    <div
                                        css={css`
                                            position: absolute;
                                            left: 10px;
                                            top: 10px;
                                            text-shadow: 1px 1px 0 #0a0a0a;
                                            font-size: 16px;
                                            font-weight: bold;
                                        `}
                                    >
                                        {device.meta.host_name}
                                    </div>

                                    <div
                                        css={css`
                                            position: absolute;
                                            right: 10px;
                                            bottom: 10px;
                                            text-shadow: 1px 1px 0 #0a0a0a;

                                            ${mqMax[0]} {
                                                font-size: 10px;
                                            }
                                        `}
                                    >
                                        {new Date(addHours(device.date, 2)).toUTCString()}
                                    </div>

                                    <div
                                        css={css`
                                            width: 100%;
                                            position: absolute;
                                            left: 10px;
                                            bottom: 10px;
                                            text-shadow: 1px 1px 0 #0a0a0a;

                                            ${mqMax[0]} {
                                                font-size: 10px;
                                            }
                                        `}
                                    >
                                        <div>IP Address: {device.meta.local_ip}</div>
                                        <div>Signal strength: {device.meta.signal_strength}</div>
                                    </div>
                                </div>

                                <div>
                                    {device.pms7003 && (
                                        <Fragment>
                                            <Box>
                                                <BoxLabel>PM1.0</BoxLabel>
                                                <BoxValue>
                                                    <div>
                                                        {device.pms7003.pm['PM1.0']} <PmUnit />
                                                    </div>
                                                    <PmNorm norm={25} value={device.pms7003.pm['PM1.0']} />
                                                </BoxValue>
                                            </Box>

                                            <Box>
                                                <BoxLabel>PM2.5</BoxLabel>
                                                <BoxValue>
                                                    <div>
                                                        {device.pms7003.pm['PM2.5']} <PmUnit />
                                                    </div>
                                                    <PmNorm norm={25} value={device.pms7003.pm['PM2.5']} />
                                                </BoxValue>
                                            </Box>

                                            <Box>
                                                <BoxLabel>PM10</BoxLabel>
                                                <BoxValue>
                                                    <div>
                                                        {device.pms7003.pm['PM10']} <PmUnit />
                                                    </div>
                                                    <PmNorm norm={50} value={device.pms7003.pm['PM10']} />
                                                </BoxValue>
                                            </Box>
                                        </Fragment>
                                    )}

                                    {device.bmp280 && (
                                        <Fragment>
                                            <Box>
                                                <BoxLabel>Temperature</BoxLabel>
                                                <BoxValue>{device.bmp280.temperature.value} °C</BoxValue>
                                            </Box>

                                            <Box>
                                                <BoxLabel>Pressure</BoxLabel>
                                                <BoxValue>{device.bmp280.pressure.value} hPa</BoxValue>
                                            </Box>
                                        </Fragment>
                                    )}
                                    {/* <pre>{JSON.stringify(device, null, 2)}</pre> */}
                                </div>
                            </section>
                        )
                )}
        </MainSection>
    );
};

export { Dashboard };

/** @jsx jsx */
import { jsx } from '@emotion/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import { AddUser } from './containers/AddUser';
import { Dashboard } from './containers/Dashboard';
import { EditUser } from './containers/EditUser';
import { Login } from './containers/Login';
import { Logout } from './containers/Logout';
import { Users } from './containers/Users';

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/dashboard" component={Layout(Dashboard)} />

                <Route path="/users" component={Layout(Users)} />
                <Route path="/user/edit/:login" component={Layout(EditUser)} />
                <Route path="/user/add" component={Layout(AddUser)} />

                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />

                <Route component={Login} />
            </Switch>
        </Router>
    );
};

export default App;

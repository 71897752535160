import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from './../utils/axios';

const Logout = () => {
    const history = useHistory();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        localStorage.removeItem('user');
        if (user) {
            axios
                .post(`/api/logout`, {
                    access_token: user.access_token
                })
                .then(response => history.push('/'))
                .catch(error => null);
        } else {
            history.push('/');
        }
    }, [history]);

    return <Fragment />;
};

export { Logout };

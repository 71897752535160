/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { TiWiFi } from 'react-icons/ti';
import { COLOR_PALETTE } from '../AppStyle';

const SymbolWifi = ({ top = 0, left = 0, scale = 1, borderColor = null }) => {
    return (
        <div
            css={css`
                position: absolute;
                top: ${top};
                left: ${left};
                transform: scale(${scale});
                width: 70px;
                height: 70px;
                background-color: ${COLOR_PALETTE.pastel_red};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: ${borderColor ? '5px solid ' + borderColor : '0'};
            `}
        >
            <TiWiFi
                css={css`
                    color: ${COLOR_PALETTE.font_white};
                    font-size: 40px;
                `}
            />
        </div>
    );
};

export { SymbolWifi };

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { MdSecurity } from 'react-icons/md';
import { COLOR_PALETTE } from '../AppStyle';

const SymbolSecurity = ({ top = 0, left = 0, scale = 1 }) => {
    return (
        <div
            css={css`
                position: absolute;
                top: ${top};
                left: ${left};
                transform: scale(${scale});
                width: 70px;
                height: 70px;
                background-color: ${COLOR_PALETTE.pastel_blue};
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            `}
        >
            <MdSecurity
                css={css`
                    color: ${COLOR_PALETTE.font_white};
                    font-size: 40px;
                `}
            />
        </div>
    );
};

export { SymbolSecurity };

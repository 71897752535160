import { Button, Divider, Form, Icon, Input, Select, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../utils/axios';
import { MainSection } from './../components/MainSection';

const { Option } = Select;

const EditUserFrom = ({ form }) => {
    const [user, setUser] = useState(null);
    const [isFetchingData, setIsFetchingData] = useState(true);
    const [confirmDirty, setConfirmDirty] = useState(false);

    const { login } = useParams();
    const history = useHistory();

    const fetchUser = useCallback(
        login => {
            axios.get(`/api/user/${login}`).then(response => {
                setUser(response.data);
                setIsFetchingData(false);
            });
        },
        [setUser, setIsFetchingData]
    );

    useEffect(() => {
        if (login) {
            fetchUser(login);
        }
    }, [login, fetchUser]);

    const handleSubmit = e => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                axios.put(`/api/user/${login}`, values).then(response => {
                    setIsFetchingData(false);
                    history.push('/users');
                });
            }
        });
    };

    const handleConfirmBlur = e => {
        const { value } = e.target;
        setConfirmDirty(confirmDirty || !!value);
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Podane hasła nie zgadzają się.');
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    const { getFieldDecorator } = form;
    const role = user ? user.role : null;
    const userLogin = user ? user.login : null;

    return (
        <MainSection showLoader={isFetchingData}>
            <Divider style={{ marginBottom: '40px' }} orientation="left">
                {' '}
                <Icon type="user" /> Edycja użytkownika <span style={{ color: '#1890ff' }}>{userLogin}</span>
            </Divider>

            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }} onSubmit={handleSubmit}>
                <Form.Item
                    label={
                        <span>
                            Login&nbsp;
                            <Tooltip title="Login przyjmuje formę adresu e-mail.">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('login', {
                        initialValue: userLogin,
                        rules: [
                            {
                                type: 'email',
                                message: 'Niepoprawny adres e-mail.'
                            },
                            {
                                required: true,
                                message: 'Pole wymagane.'
                            }
                        ]
                    })(<Input autoComplete="email" disabled />)}
                </Form.Item>

                <Form.Item label="Hasło" hasFeedback>
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                message: 'Pole wymagane.'
                            },
                            {
                                validator: validateToNextPassword
                            }
                        ]
                    })(<Input.Password autoComplete="new-password" />)}
                </Form.Item>

                <Form.Item label="Powtórz hasło" hasFeedback>
                    {getFieldDecorator('confirm', {
                        rules: [
                            {
                                message: 'Powtórz hasło.'
                            },
                            {
                                validator: compareToFirstPassword
                            }
                        ]
                    })(<Input.Password onBlur={handleConfirmBlur} autoComplete="new-password" />)}
                </Form.Item>
                <Form.Item label="Rola">
                    {getFieldDecorator('role', {
                        initialValue: role,
                        rules: [{ required: true, message: 'Pole wymagane.' }]
                    })(
                        <Select placeholder="Wybierz rolę dla użytkownika systemu" onChange={value => console.log({ value })}>
                            <Option value="ADMIN">Administrator</Option>
                            <Option value="USER">Użytkownik</Option>
                        </Select>
                    )}
                </Form.Item>

                <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                    <Button type="primary" htmlType="submit">
                        Zapisz zmiany
                    </Button>
                </Form.Item>
            </Form>
        </MainSection>
    );
};

export const EditUser = Form.create({ name: 'edit-user-form' })(EditUserFrom);

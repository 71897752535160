/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Loader } from './Loader';

const MainSection = ({ showLoader, children }) => {
    return (
        <div
            css={css`
                position: relative;
                width: 100%;
                height: auto;
                box-sizing: border-box;
                overflow: auto;
            `}
        >
            {showLoader ? <Loader /> : children}
        </div>
    );
};

export { MainSection };

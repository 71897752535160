import { message, PageHeader, Popconfirm, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MainSection } from '../components/MainSection';
import axios from '../utils/axios';

const Users = () => {
    const [users, setUsers] = useState(null);

    const fetchUsers = useCallback(() => {
        axios.get(`/api/users`).then(response => {
            setUsers(response.data);
        });
    }, [setUsers]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const deleteUser = useCallback(
        login => {
            axios.delete(`/api/user/${login}`).then(response => {
                fetchUsers();
            });
        },
        [fetchUsers]
    );

    const confirm = useCallback(
        (event, login) => {
            event.preventDefault();
            message.success('Zatwierdzono akcję.');
            setUsers(null);
            deleteUser(login);
        },
        [setUsers, deleteUser]
    );

    const cancel = useCallback(event => {
        event.preventDefault();
        message.error('Anulowano akcję.');
    }, []);

    const columns = [
        {
            title: 'Login',
            dataIndex: 'login'
        },
        {
            title: 'Rola',
            dataIndex: 'role'
        },
        {
            title: 'Ostatnie logowanie',
            render: (text, record) => <span>{record.last_login ? new Date(record.last_login).toLocaleDateString() : ''}</span>
        },
        {
            title: 'Akcje',
            render: (text, record) => (
                <section>
                    <Link to={`/user/edit/${record.login}`}>Edytuj</Link> |{' '}
                    <Popconfirm
                        title="Czy napewno usunąć wybranego kontrahenta?"
                        onConfirm={event => confirm(event, record.login)}
                        onCancel={cancel}
                        okText="Tak"
                        cancelText="Nie"
                    >
                        <a href="/">Usuń</a>
                    </Popconfirm>
                </section>
            )
        }
    ];

    return (
        <MainSection showLoader={users === null}>
            <PageHeader title="Zarządzaj użytkownikami" subTitle="Lista aktywnych użytkownikow systemu" />
            <Table rowKey={obj => obj._id} dataSource={users} columns={columns} pagination={false} />
        </MainSection>
    );
};

export { Users };

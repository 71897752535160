/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { COLOR_PALETTE, globalStyles, mqMax, mqMin, PASTEL_PALETTE } from './../AppStyle';
import { Logo } from './../components/Logo';
import { SymbolBulb } from './../components/SymbolBulb';
import { SymbolPlug } from './../components/SymbolPlug';
import { SymbolSecurity } from './../components/SymbolSecurity';
import axios from './../utils/axios';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
});

const Label = styled.label`
    display: block;
    color: ${COLOR_PALETTE.font_white};
    margin-bottom: 5px;
    font-size: 16px;
`;

const Input = styled.input`
    background-color: ${COLOR_PALETTE.background_dark_blue};
    color: ${COLOR_PALETTE.font_white};
    padding: 10px;
    border: 0;
    outline: none;
    width: 300px;
    border-bottom: 3px solid ${COLOR_PALETTE.pastel_green};
`;

const EmailInput = ({ onChange }) => <Input type="text" placeholder="john@doe.com" onChange={e => onChange(e.target.value)} />;
const PasswordInput = ({ onChange }) => <Input type="password" placeholder="Password" onChange={e => onChange(e.target.value)} />;

const Login = () => {
    const history = useHistory();

    const { handleSubmit, errors, setError, control } = useForm({
        validationSchema: schema,
        submitFocusError: false
    });

    useEffect(() => {
        if (localStorage.getItem('user')) {
            history.push('/dashboard');
        }
    }, [history]);

    const getError = () => {
        if (errors.email) {
            return errors.email.message;
        }

        if (errors.password) {
            return errors.password.message;
        }

        return null;
    };

    const onSubmit = data => {
        const { email, password } = data;

        return axios
            .post(`/api/login`, { login: email, password })
            .then(response => {
                if (response) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    history.push('/dashboard');
                } else {
                    setError('password', 'required', 'Invalid credentials.');
                }
            })
            .catch(error => {
                setError('password', 'required', 'Upsss... something went wrong. Try again.');
            });
    };

    return (
        <section
            css={css`
                display: flex;
                position: fixed;
                flex-direction: row;
                height: 100%;
                width: 100%;

                ${mqMax[1]} {
                    flex-direction: column;
                }
            `}
        >
            <Global styles={globalStyles} />
            <div
                css={css`
                    width: 60%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    ${mqMax[1]} {
                        width: 100%;
                        height: 50%;
                    }

                    ${mqMax[0]} {
                        height: 40%;
                    }
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-image: url('./landing-bg.jpg');
                        background-size: cover;
                        background-position: center center;
                        z-index: 1;
                    `}
                ></div>

                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-color: ${COLOR_PALETTE.background_navy_blue};
                        opacity: 0.8;
                        z-index: 2;
                    `}
                ></div>
                <div
                    css={css`
                        position: relative;
                        z-index: 3;

                        ${mqMax[1]} {
                            transform: scale(0.7);
                        }

                        ${mqMax[0]} {
                            transform: scale(0.4);
                        }
                    `}
                >
                    <SymbolPlug top="93px" left="-93px" scale={0.7} />
                    <SymbolBulb top="-110px" left="84px" scale={1.3} />
                    <SymbolSecurity top="-41px" left="-61px" scale={1.2} />
                    <Logo backgroundColor={COLOR_PALETTE.background_dark_blue} />
                    <div
                        css={css`
                            color: ${COLOR_PALETTE.font_white};
                            position: absolute;
                            right: -199px;
                            bottom: 0px;
                            width: 200px;
                        `}
                    >
                        <h1
                            css={css`
                                color: ${COLOR_PALETTE.font_white};
                                margin-bottom: -28px;
                            `}
                        >
                            iHome
                        </h1>
                        <h5
                            css={css`
                                color: ${COLOR_PALETTE.font_white};
                                opacity: 0.6;
                            `}
                        >
                            Smart Home Solutions
                        </h5>
                    </div>
                </div>
            </div>

            <div
                css={css`
                    width: 40%;
                    background-color: ${COLOR_PALETTE.background_graphite};
                    display: flex;
                    align-items: center;

                    ${mqMax[1]} {
                        width: 100%;
                        height: 50%;
                        justify-content: center;
                    }

                    ${mqMax[0]} {
                        height: 60%;
                    }
                `}
            >
                <div
                    css={css`
                        ${mqMin[1]} {
                            padding-left: 10%;
                            margin-top: -80px;
                        }
                    `}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Label>E-mail</Label>
                        <Controller control={control} name="email" as={EmailInput} />

                        <Label
                            css={css`
                                margin-top: 20px;
                            `}
                        >
                            Password
                        </Label>
                        <Controller control={control} name="password" as={PasswordInput} />

                        {getError() && (
                            <div
                                css={css`
                                    color: ${COLOR_PALETTE.pastel_red};
                                `}
                            >
                                {getError()}
                            </div>
                        )}

                        <button
                            css={css`
                                display: block;
                                border: 0;
                                color: ${COLOR_PALETTE.font_white};
                                background-color: ${COLOR_PALETTE.pastel_green};
                                margin-top: 20px;
                                font-weight: bold;
                                text-align: center;
                                width: 300px;
                                padding: 10px;
                                text-shadow: 1px 1px rgba(0, 0, 0, 0.5);

                                &:hover {
                                    background-color: ${COLOR_PALETTE.pastel_red};
                                    cursor: pointer;
                                }
                            `}
                            type="submit"
                        >
                            Sign in
                        </button>
                    </form>
                </div>
            </div>

            <ul
                css={css`
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 60%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: stretch;
                    height: 10px;
                    position: fixed;
                    bottom: 0;
                    z-index: 5;

                    ${mqMax[1]} {
                        width: 100%;
                    }
                `}
            >
                {Object.keys(PASTEL_PALETTE).map((color, index) => (
                    <li
                        key={index}
                        css={css`
                            height: 10px;
                            flex-grow: 1;
                            background-color: ${PASTEL_PALETTE[color]};
                        `}
                    ></li>
                ))}
            </ul>
        </section>
    );
};

export { Login };

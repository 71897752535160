/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import emotionNormalize from 'emotion-normalize';
import { useCallback, useState } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { FcConferenceCall, FcElectricalSensor } from 'react-icons/fc';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { COLOR_PALETTE, globalStyles, mqMax } from '../AppStyle.js';

const List = styled.ul`
    color: ${COLOR_PALETTE.font_white};
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li {
        margin: 10px 0;
    }
`;

const ListItem = styled.li`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: relative;

    div {
        display: none;
    }

    &:hover {
        cursor: pointer;

        div {
            display: block;
        }
    }
`;

const Tooltip = styled.div`
    position: absolute;
    background-color: ${COLOR_PALETTE.background_blue};
    color: ${COLOR_PALETTE.font_white};
    font-size: 12px;
    border-radius: 5px;
    padding: 5px;
    left: 43px;
    text-align: center;

    &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px;
        border-color: transparent ${COLOR_PALETTE.background_blue} transparent transparent;
        position: absolute;
        top: 9px;
        left: -10px;
    }
`;

const PATHS = {
    DASHBOARD: '/dashboard',
    USERS: '/users',
    LOGOUT: '/logout'
};

const Layout = InnerComponent => {
    const Component = props => {
        const history = useHistory();
        const isDashboardPath = useRouteMatch(PATHS.DASHBOARD);
        const isUsersPath = useRouteMatch(PATHS.USERS);
        // const isLogoutPath = useRouteMatch(PATHS.LOGOUT);

        const [user] = useState(JSON.parse(localStorage.getItem('user')));

        const redirectTo = useCallback(
            path => {
                history.push(path);
            },
            [history]
        );

        if (!user) {
            history.push('/');
        }

        if (user.role !== 'ADMIN') {
            history.push('/dashboard');
        }

        return (
            <section
                css={css`
                    display: flex;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: grey;
                    align-items: space;
                `}
            >
                <Global
                    styles={css`
                        ${emotionNormalize} ${globalStyles}
                    `}
                />
                <div
                    css={css`
                        position: fixed;
                        height: 100%;
                        top: 0;
                        left: 0;
                        width: 60px;
                        background-color: ${COLOR_PALETTE.background_navy_blue};
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        z-index: 10;

                        ${mqMax[0]} {
                            display: none;
                        }
                    `}
                >
                    <List>
                        <ListItem
                            onClick={() => redirectTo(PATHS.DASHBOARD)}
                            css={css`
                                margin-top: 30px !important;
                                background-color: ${isDashboardPath ? COLOR_PALETTE.background_gray_light : COLOR_PALETTE.font_white};
                            `}
                        >
                            <FcElectricalSensor />
                            <Tooltip
                                css={css`
                                    width: 96px;
                                `}
                            >
                                Sensors
                            </Tooltip>
                        </ListItem>
                        <ListItem
                            onClick={() => redirectTo(PATHS.USERS)}
                            css={css`
                                background-color: ${isUsersPath ? COLOR_PALETTE.background_gray_light : COLOR_PALETTE.font_white};
                            `}
                        >
                            <FcConferenceCall />
                            <Tooltip
                                css={css`
                                    width: 123px;
                                `}
                            >
                                Manage users
                            </Tooltip>
                        </ListItem>
                    </List>

                    <List>
                        <li>
                            <img
                                css={css`
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                `}
                                src="http://s3.amazonaws.com/37assets/svn/765-default-avatar.png"
                                alt="profile name"
                            />
                        </li>
                        <ListItem
                            onClick={() => redirectTo(PATHS.LOGOUT)}
                            css={css`
                                margin-top: 0px !important;
                                margin-bottom: 20px !important;
                                background-color: ${COLOR_PALETTE.background_graphite};
                                color: ${COLOR_PALETTE.pastel_red};
                            `}
                        >
                            <AiOutlineLogout />
                            <Tooltip
                                css={css`
                                    width: 59px;
                                `}
                            >
                                Logout
                            </Tooltip>
                        </ListItem>
                    </List>
                </div>

                <div
                    css={css`
                        background-color: ${COLOR_PALETTE.background_graphite};
                        width: calc(100% - 60px);
                        margin-left: 60px;
                        padding: 10px;
                        display: flex;
                        align-items: stretch;
                        overflow: auto;

                        ${mqMax[0]} {
                            margin-left: 0px;
                            width: 100%;
                        }
                    `}
                >
                    <div
                        css={css`
                            background-color: ${COLOR_PALETTE.background_dark_blue};
                            width: 100%;
                            overflow: auto;
                            padding: 10px;
                            color: ${COLOR_PALETTE.font_white};
                        `}
                    >
                        <InnerComponent {...props} />
                    </div>
                </div>
            </section>
        );
    };

    return Component;
};

export { Layout };

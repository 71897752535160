/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import HashLoader from 'react-spinners/HashLoader';
import { COLOR_PALETTE } from './../AppStyle';

const Loader = () => {
    return (
        <section
            css={css`
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(230, 247, 255, 0.5);
            `}
        >
            <div
                css={css`
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto auto;
                    width: 150px;
                    height: 150px;
                    background-color: #fff;
                    border-radius: 5px;
                    -webkit-box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);
                    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.3);

                    display: flex;
                    align-items: center;
                    justify-content: center;
                `}
            >
                <HashLoader size={80} color={COLOR_PALETTE.pastel_orange} loading={true} />
            </div>
        </section>
    );
};

export { Loader };

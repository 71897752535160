/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { COLOR_PALETTE } from '../AppStyle';
import { SymbolWifi } from './SymbolWifi';

const Logo = ({ backgroundColor, scale = 1 }) => {
    return (
        <section
            css={css`
                position: relative;
                height: 250px;
                width: 250px;
                transform: scale(${scale});
                padding: 95px 0 0 70px;
                border-radius: 50%;
            `}
        >
            <div
                css={css`
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: 250px;
                    width: 250px;
                    background-color: ${backgroundColor};
                    border-radius: 50%;
                    opacity: 0.8;
                    z-index: 1;
                `}
            ></div>
            <div
                css={css`
                    position: relative;
                    height: 110px;
                    width: 110px;
                    z-index: 2;
                `}
            >
                <div
                    css={css`
                        width: 85%;
                        height: 10px;
                        border-radius: 5px;
                        background-color: ${COLOR_PALETTE.background_gray};
                        transform: rotate(-45deg) translateX(-5px) translateY(-33px);
                    `}
                ></div>

                <div
                    css={css`
                        width: 85%;
                        height: 10px;
                        border-radius: 5px;
                        background-color: ${COLOR_PALETTE.background_gray};
                        transform: rotate(45deg) translateX(2px) translateY(-45px);
                    `}
                ></div>
                <div
                    css={css`
                        &:after {
                            margin-top: -100px;
                            content: '';
                            width: 0;
                            height: 0;
                            position: absolute;
                            border-style: solid;
                            border-width: 50px;
                            border-color: transparent transparent ${COLOR_PALETTE.font_white} transparent;
                        }
                    `}
                ></div>
                <div
                    css={css`
                        width: 100px;
                        height: 62px;
                        background-color: ${COLOR_PALETTE.font_white};
                    `}
                ></div>
                <SymbolWifi top="40px" left="60px" borderColor={backgroundColor} />
            </div>
        </section>
    );
};

export { Logo };

import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'));
        const access_token = user ? user.access_token : null;
        config.headers['Authorization'] = `Bearer ${access_token}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => response,
    error => {
        console.error({ error });
        if (401 === error.response?.status) {
            window.location.href = window.location.origin;
        } else {
            return Promise.reject(error);
        }
    }
);

export default axios;
